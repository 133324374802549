<template>
  <div class="produk-detail">
    <div class="produk-detail-header">
      <h2> Produk Detail </h2>
    </div>
    <div v-for="dataProduk in dataProduks" :key="dataProduk.itemLink" >
        <div class="sidaber-left" v-if="dataProduk.itemLink == produk">
            <Carousel :wrap-around="true" :settings="settings" >
                <Slide v-for="slide in dataProduk.itemImgs" :key="slide.id">
                <div class="carousel__item">
                    <img :src="getImage(slide.img)" @click="slide.imgModal = true" v-bind:alt="slide.img" />
                    <it-modal v-model="slide.imgModal">
                        <template #image>
                            <img :src="getImage(slide.img)" />
                        </template>
                    </it-modal>
                </div>
                </Slide>
                <template #addons >
                    <Navigation v-if="dataProduk.itemImgs.length > 1" />
                </template>
            </Carousel>
        </div>
        <div class="produk-detail-wrapper" v-if="dataProduk.itemLink == produk">
            <h3>{{ dataProduk.itemName }}</h3>
            <it-collapse>
                <it-collapse-item title="Custom Order" :opened=true >
                    <it-select label-top="Bahan" v-model="dataOrder.bahanOrder" :options="dataProduk.itemBahans" />
                    <it-select label-top="Ukuran" v-model="dataOrder.ukuranOrder" :options="dataOrder.optionUkuranOrder" />
                    <it-select label-top="Gramasi" v-model="dataOrder.gramasiOrder" :options="dataOrder.optionGramasiOrder" />
                    <it-switch v-if="dataProduk.itemLaminasi" v-model="dataProduk.laminasiOrder" label="Laminasi" />
                </it-collapse-item>
            </it-collapse>
            <it-collapse>
                <it-collapse-item title="Jumlah Order / Item" :opened=true >
                    <it-number-input v-model="dataOrder.jumlahOrder" :min=0 :max=10000 :step=100 :resize-on-write=true />
                </it-collapse-item>
            </it-collapse>
            <it-button size="big" type="primary" @click="createOrder">Create Order</it-button>
        </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  name: 'DetailProduk',
  components: {
    Carousel,
    Slide,
    Navigation
  },
  data () {
    return {
      produk: '',
      indexProduk: 0,
      catalogueBaseUrl: 'catalogue/',
      dataProduks: [
        {
          itemLink: 'sos-bag',
          itemName: 'SOS BAG',
          itemImgs: [
            { imgModal: false, img: 'sos-bag.png' }
          ],
          itemBahans: ['White Kraft', 'Brown Kraft', 'Greaseproof'],
          itemUkurans: [
            ['23 x 13 x 7.5cm', '17.5 x 13 x 7.5cm', '30 x 15 x 10cm', '30 x 20 x 10cm', 'Custom'],
            ['23 x 13 x 7.5cm', '17.5 x 13 x 7.5cm', '30 x 15 x 10cm', '30 x 20 x 10cm', 'Custom'],
            ['23 x 13 x 7.5cm', '17.5 x 13 x 7.5cm']],
          itemGramasis: ['50gsm'],
          itemLaminasi: false
        },
        {
          itemLink: 'flat-bag',
          itemName: 'FLAT BAG',
          itemImgs: [
            { imgModal: false, img: 'flat-bag.png' }
          ],
          itemBahans: ['White Kraft', 'Brown Kraft', 'Greaseproof'],
          itemUkurans: ['Custom'],
          itemGramasis: [
            ['50gsm'],
            ['50gsm'],
            ['50gsm & 40gsm']],
          itemLaminasi: false
        },
        {
          itemLink: 'sactel-bag',
          itemName: 'SATCHEL BAG',
          itemImgs: [
            { imgModal: false, img: 'satchel-bag.jpeg' }
          ],
          itemBahans: ['White Kraft', 'Brown Kraft', 'Greaseproof'],
          itemUkurans: [
            ['22.5 x 12.5 x 8cm'],
            ['22.5 x 12.5 x 8cm'],
            ['22.5 x 12.5 x 8cm', '22.5 x 11.5 x 7cm', '28 x 13 x 8cm', 'Custom']],
          itemGramasis: [
            ['50gsm'],
            ['50gsm'],
            ['50gsm & 40gsm']],
          itemLaminasi: false
        },
        {
          itemLink: 'shopping-bag',
          itemName: 'Shopping Bag',
          itemImgs: [
            { imgModal: false, img: 'shopping-bag.jpeg' }
          ],
          itemBahans: ['White Kraft', 'Brown Kraft', 'Ivory', 'Duplex'],
          itemUkurans: ['Custom'],
          itemGramasis: [
            ['120 gsm'],
            ['70gsm & 120gsm'],
            ['250gsm', '270gsm', '300gsm'],
            ['250gsm', '270gsm', '310gsm']],
          itemLaminasi: false
        },
        {
          itemLink: 'lunch-box',
          itemName: 'Lunch Box',
          itemImgs: [
            { imgModal: false, img: 'lunch-box.jpeg' }
          ],
          itemBahans: ['Brown Kraft', 'Ivory', 'Duplex'],
          itemUkurans: ['Custom'],
          itemGramasis: [
            ['250gsm', '275gsm', '300gsm'],
            ['250gsm', '270gsm', '300gsm'],
            ['250gsm', '270gsm', '310gsm']],
          itemLaminasi: true
        },
        {
          itemLink: 'box-nasi',
          itemName: 'Box Nasi',
          itemImgs: [
            { imgModal: false, img: 'box.jpeg' }
          ],
          itemBahans: ['Brown Kraft', 'Ivory', 'Duplex'],
          itemUkurans: ['Custom'],
          itemGramasis: [
            ['250gsm', '275gsm', '300gsm'],
            ['250gsm', '270gsm', '300gsm'],
            ['250gsm', '270gsm', '310gsm']],
          itemLaminasi: true
        },
        {
          itemLink: 'food-pail',
          itemName: 'Food Pail',
          itemImgs: [
            { imgModal: false, img: 'food-pail.jpeg' }
          ],
          itemBahans: ['Ivory', 'Duplex'],
          itemUkurans: [
            ['Size M (8 x 8 x 6.5cm)', 'Size L (10.5 x 8 x 6.5cm)'],
            ['Size M (8 x 8 x 6.5cm)', 'Size L (10.5 x 8 x 6.5cm)']],
          itemGramasis: [
            ['250gsm'],
            ['250gsm', '270gsm']],
          itemLaminasi: true
        },
        {
          itemLink: 'lunch-tray',
          itemName: 'Lunch Tray',
          itemImgs: [
            { imgModal: false, img: 'paper-tray.jpeg' }
          ],
          itemBahans: ['Ivory', 'Duplex'],
          itemUkurans: [
            ['Size M (16 x 9 x 5cm)', 'Size L (18 x 11 x 5cm)'],
            ['Size M (16 x 9 x 5cm)', 'Size L (18 x 11 x 5cm)']],
          itemGramasis: ['270gsm'],
          itemLaminasi: true
        },
        {
          itemLink: 'kertas-nasi',
          itemName: 'Kertas Nasi',
          itemImgs: [
            { imgModal: false, img: 'kertas-nasi.jpeg' }
          ],
          itemBahans: ['MG Paper'],
          itemUkurans: [
            ['22.5 x 27cm', '25 x 27cm', '25 x 25cm', 'custom'],
            ['22.5 x 27cm', '25 x 27cm', '25 x 25cm', 'custom']],
          itemGramasis: ['22gsm + PE6'],
          itemLaminasi: false
        },
        {
          itemLink: 'paper-bowl',
          itemName: 'Paper Bowl',
          itemImgs: [
            { imgModal: false, img: 'cupstock.jpeg' }
          ],
          itemBahans: ['Cupstock'],
          itemUkurans: [['360ml', '500ml', '650ml', '800ml'], ['360ml', '500ml', '650ml', '800ml']],
          itemGramasis: ['-'],
          itemLaminasi: false
        }
      ],
      settings: {
        itemsToShow: 1,
        snapAlign: 'center'
      },
      dataOrder: {
        jumlahOrder: 0,
        bahanOrder: '',
        ukuranOrder: '',
        gramasiOrder: '',
        laminasiOrder: false,
        optionUkuranOrder: [],
        optionGramasiOrder: []
      }
    }
  },
  methods: {
    getImage (imgName) {
      var image = require.context('../assets/image/', false)
      return image('./' + imgName)
    },
    createOrder () {
      const laminasiOrder = this.dataOrder.laminasiOrder ? 'Ya' : 'No'
      var message = 'Hallo Gigihpack, saya mau order ' + this.dataProduks[this.indexProduk].itemName + ' dengan detail order : \n ' +
        'Bahan : ' + this.dataOrder.bahanOrder + ' \n ' +
        'Ukuran : ' + this.dataOrder.ukuranOrder + ' \n ' +
        'Gramasi : ' + this.dataOrder.gramasiOrder + ' \n ' +
        'Laminasi : ' + laminasiOrder + ' \n ' +
        'Jumlah : ' + this.dataOrder.jumlahOrder + ' Item \n' +
        'Ditunggu responsnya terima kasih.'
      window.open('https://wa.me/6281383065737/?text=' + encodeURI(message), '_blank')
    }
  },
  mounted () {
    this.produk = !this.$route.params.produk ? 'sos-bag' : this.$route.params.produk
    this.indexProduk = this.dataProduks.findIndex(object => {
      return object.itemLink === this.$route.params.produk
    })
  },
  watch: {
    'dataOrder.bahanOrder' (newVal, oldVal) {
      const indexBahan = this.dataProduks[this.indexProduk].itemBahans.indexOf(newVal)
      this.dataOrder.optionUkuranOrder = this.dataProduks[this.indexProduk].itemUkurans.length > 1 ? this.dataProduks[this.indexProduk].itemUkurans[indexBahan] : this.dataProduks[this.indexProduk].itemUkurans
      this.dataOrder.optionGramasiOrder = this.dataProduks[this.indexProduk].itemGramasis.length > 1 ? this.dataProduks[this.indexProduk].itemGramasis[indexBahan] : this.dataProduks[this.indexProduk].itemGramasis
    }
  }
}
</script>
<style lang="scss">
  #wrapper-content{
    background: #ededed;
  }
  .produk-detail{
    position: relative;
    height: auto;
    min-height: 90vh;
    padding: 2.5rem;
    padding-bottom: 5rem;
    text-align: left;
    display: block;
    overflow: auto;
    .produk-detail-header{
      background-color: #17954c;
      margin: -2.5rem;
      padding: .6rem 3rem;
      border-radius: 0.8rem 0.8rem 0 0;
      margin-bottom: 2.8rem;
      -moz-box-shadow: inset 0px -3px 3px #126e38;
      -webkit-box-shadow: inset 0px -3px 3px #126e38;
      box-shadow: inset 0px -3px 3px #126e38;
      h2{
        color:#fff;
        text-shadow: 0px 1px 1px #000;
      }
    }
    .sidaber-left{
      float: left;
      width: 30%;
      filter: drop-shadow(0px 2px 4px #969696);
      .carousel__item{
        margin: 0px;
        padding: 0px;
        img{
          border-radius: 5px;
        }
      }
    }
    .produk-detail-wrapper{
      float: left;
      width: 65%;
      height: auto;
      display: block;
      min-height: 30vh;
      border-radius: 0.3rem;
      margin-left: 3rem;
      h3{
        margin-left: 1rem;
        margin-top: 0px;
        color: #17954c;
        text-shadow: 0px 1px 1px #179546;
        letter-spacing: .1rem;
        font-weight: 600;
      }
      .it-collapse{
        margin-bottom: 1rem;
      }
      .it-btn{
        float: right;
      }
      .it-select{
        width: 100%;
      }
      .it-switch-wrapper{
        margin-top: 1rem;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .produk-detail{
      .produk-detail-wrapper, .sidaber-left{
        width: 100%;
        margin: 0px;
        h3{
            margin-top: 2rem;
        }
      }
    }
  }
</style>
